@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import "~leaflet/dist/leaflet.css";

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
//@import "./_custom.scss";
@import "./style.bundle.css";
@import "~angular-tree-component/dist/angular-tree-component.css";
// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

;

.modal-open .modal {
  z-index: 1056 !important;
}

@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'hdscreen';

nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 1.25rem 1.25rem 0.75rem;
}

.mdc-menu-surface.mat-mdc-select-panel {
  background-color: var(--option-list-background-color);
  border-color: var(--option-list-border-color);
  border-style: var(--option-list-border-style);
  border-width: var(--option-list-border-width);
  border-radius: var(--option-list-border-radius);
  box-shadow: var(--option-list-shadow);
}

.mat-mdc-text-field-wrapper {
  line-height: 30px;
}

.user-sidebar {
  .scrollable {
    width: 250px;
    //background: #efefef;
    //background: var(--user-side-bar);
    padding: 0 !important;
  }
}

.user-sidebar.expanded {
  width: 250px !important;
}

.autocomplete-container .input-container input {
  background-color: var(--input-basic-background-color) !important;
  border-color: var(--input-basic-border-color) !important;
  color: var(--input-basic-text-color) !important;
}

.mat-mdc-form-field :hover {
  background-color: var(--select-outline-primary-background-color);
  border-color: var(--select-outline-primary-border-color);
  color: var(--select-outline-primary-text-color);
}


mat-option:hover {
  background-color: var(--option-hover-background-color);
  color: var(--option-hover-text-color);
  cursor: pointer;
}

.mdc-list-item--selected {
  background-color: var(--option-selected-focus-background-color) !important;
  color: var(--option-selected-focus-text-color) !important;

  mat-option {
    background-color: var(--option-selected-focus-background-color) !important;
    color: var(--option-selected-focus-text-color) !important;
  }
}

.mat-mdc-paginator-page-size-select {
  width: 73px !important;
}

@media screen and (max-width: 1024px) {
  // .user-sidebar {
    // margin-left: 55px;
  // }
  // nb-sidebar.compacted.left.fixed ~ .content {
    // margin-left: 0;
  // }
  .table-content {
    height: calc(100vh - 251px - 15px) !important;
  }
}

.node-content-wrapper {
  max-width: 160px;
}

nb-layout-column:has(ngx-search-map) , nb-layout-column:has(ngx-charge-station)  {
  padding-top: unset !important;
  padding-bottom: unset !important;
  padding-right: unset !important;
  padding-left: unset !important;
}

.map-tooltip {
  font-size: 13px;
  font-weight: 500 !important;
  background: var(--sidebar-background-color);
  padding: 12px;
  //width: max-content;
  //max-width: 300px;
  width: 300px;
  height: auto;
  border-radius: 2px !important;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .3) !important;
  border: 2px;

  .name {
    font-weight: 500;
    font-family: Roboto, Arial, sans-serif !important;
    padding-bottom: 1px;
    font-size: 14px;
  }

  .address {
    opacity: 0.87;
    font-family: Roboto, Arial, sans-serif !important;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }


}

.map-tooltip-arrow::after {
  transform: translate3d(60%, -500%, 0);;
  background: var(--sidebar-background-color);
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 15px;
  left: auto;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 28px;
}

.ngx-slider .ngx-slider-pointer {
  transform: scale(0.5) translateX(-15px) !important;
}

.node {
  //box-shadow: var(--input-basic-border-color) !important;
  border-radius: 6px;
  padding: 8px;
  //background-color: var(--input-basic-background-color) !important;
  border-color: var(--input-basic-border-color) !important;
  color: var(--input-basic-text-color) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.toggle-children {
  top: 11px;
  left: 13px;
}

.node-content-wrapper {
  width: 100%;
  max-width: 100% !important;
  margin-left: -15px;
}

.node-wrapper {
  width: 100%
}

tree-node-wrapper {
  position: relative;
}

.node-content-wrapper-active, .node-content-wrapper.node-content-wrapper-active:hover, .node-content-wrapper-active.node-content-wrapper-focused {
  background: unset;
  box-shadow: unset;

  .node {
    background: var(--tree-focus) !important;
    color: var(--input-basic-text-color) !important;
  }
}

.node-content-wrapper-active, .node-content-wrapper-focused, .node-content-wrapper:hover {
  box-shadow: unset;
  background: unset;
}

nb-layout.window-mode {
  .layout nb-layout-header, .scrollable-container {
    max-width: unset !important;
  }

  .scrollable-container {
    height: 100vh;
  }
}

@media screen and (min-width: 2220px) {
  nb-layout.window-mode nb-layout-header.fixed {
    top: 0!important;
  }
  nb-layout.window-mode {
    padding-top: 0!important;;
  }
  nb-layout.window-mode .layout nb-layout-header nav {
    max-width: 100vw;
    margin: 0 auto;
  }
}
