
.units {
  font-size: 1.2rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #3366ff;
  color: #efefef;
}

.nb-theme-dark .ng-select.ng-select-opened > .ng-select-container {
  background-color: #222b45;
  border: 1px solid #151a30;
}

.nb-theme-dark .ng-select .ng-select-container {
  background-color: #192038;
  border: 1px solid #151a30;
  color: #efefef;
}

.nb-theme-dark .ng-select .ng-select-container:hover {
  border: 1px solid #3366ff;
}

.nb-theme-dark .ng-select.ng-select-multiple
.ng-select-container
.ng-value-container
.ng-value {
  background-color: #192038;
}

.nb-theme-dark div.dropdown button {
  font-size: 0.9rem;
}

.nb-theme-dark div.treeview-header input.form-control {
  font-size: 0.9rem;
}

.nb-theme-dark .btn-outline-secondary {
  color: #6c757d !important;
  border: 1px solid #151a30 !important;
  background-color: #192038 !important;
}

.nb-theme-dark .btn-outline-secondary:hover {
  color: #6c757d !important;
  border: 1px solid #007bff !important;
  background-color: #192038 !important;
}

.nb-theme-dark .btn-outline-secondary:not(:disabled):not(.disabled):active,
.nb-theme-dark .btn-outline-secondary:not(:disabled):not(.disabled).active,
.nb-theme-dark .show > .btn-outline-secondary.dropdown-toggle {
  color: #6c757d !important;
  border: 1px solid #007bff !important;
  background-color: #192038 !important;
}

.nb-theme-dark .dropdown-menu {
  color: white !important;
  background-color: #222b45 !important;
  font-size: 0.9rem;
  width: 100%;
}

.nb-theme-dark .ng-select.ng-select-multiple
.ng-select-container
.ng-value-container
.ng-value
.ng-value-icon.left {
  border-right: 1px solid #212b44;
}

.nb-theme-dark .ng-select.ng-select-multiple
.ng-select-container
.ng-value-container
.ng-value
.ng-value-icon:hover {
  background-color: #192038;
  color: #3366ff;
}

.nb-theme-dark .ng-dropdown-panel .scroll-host {
  background-color: #192038;
  border: 1px solid #151a30;
}

.nb-theme-default .ng-dropdown-panel .scroll-host {
  background-color: #ffffff;
  // border: 1px solid #151a30;
}

.nb-theme-dark .ng-dropdown-panel.ng-select-bottom {
  background-color: #192038;
  //border: 1px solid #151a30;
}

.nb-theme-dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #222b45;
  color: #eee;
}

.nb-theme-dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #192038;
  color: #eee;
}

.ng-select .ng-select-container {
  font-size: 0.9rem;
}

.ng-select.ng-select-single .ng-select-container {
  height: 36px;
  background-color: var(--select-outline-basic-background-color);
  border-color: var(--select-outline-basic-border-color);
  color: var(--select-outline-basic-text-color);
  .ng-value-container{
    color: var(--select-outline-basic-text-color);
    font-size: var(--select-medium-text-font-size);
    font-weight: var(--select-medium-text-font-weight);
    line-height: var(--select-medium-text-line-height);
  }
}

.nb-theme-dark nb-select.appearance-outline.status-primary .select-button {
  border: 1px solid #151a30;
}

.nb-theme-dark nb-icon {
  font-size: 1rem;
}

.border-top-custom {
  border-top: 1px solid rgba(32, 24, 24, 0.87) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  // color: #ccc;
  color: var(--select-outline-basic-text-color);
  font-size: var(--select-medium-text-font-size);
  font-weight: var(--select-medium-text-font-weight);
  line-height: var(--select-medium-text-line-height);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  font-size: var(--option-medium-text-font-size);
  font-weight: var(--option-medium-text-font-weight);
  line-height: var(--option-medium-text-line-height);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: var(--option-medium-text-font-size);
  font-weight: var(--option-medium-text-font-weight);
  line-height: var(--option-medium-text-line-height);
}

.nb-theme-dark [nbInput].size-medium {
  font-weight: normal;
}

.nb-theme-dark .ng-dropdown-panel,
.nb-theme-dark .ng-dropdown-panel {
  border-color: #151a30;
}

.nb-theme-dark .ng-dropdown-panel.ng-select-top {
  border-bottom: #151a30;
}

.nb-theme-dark nb-menu .menu-group,
.nb-theme-dark nb-menu .menu-item a {
  font-weight: normal;
}

.index-col {
  width: 80px !important;
}

.code-col {
  width: 200px !important;
}

.disabled-focus:focus {
  outline: none !important;
}

.col-header-break {
  white-space: initial;
}

.row-data-show {
  word-break: break-word;
}

.nb-theme-dark .slide-nav nb-icon {
  color: #ffffff;
}

nb-toastr-container {
  z-index: 2000;
}

.toast.toast-error {
  background-color: #bd362f;
}

.toast.toast-success {
  background-color: #51a351;
}

.toast.toast-info {
  background-color: #2f96b4;
}

.nb-theme-dark .lists-group-permission .kt-portlet {
  margin-bottom: 0px !important;
  background-color: var(--input-basic-background-color);
}

.nb-theme-dark nb-toggle.status-basic .toggle {
  background-color: 	var(--color-basic-transparent-200); 
  border-color: var(--toggle-basic-border-color);
}

.nb-theme-dark nb-toggle.status-basic .toggle.checked {
  background-color: var(--toggle-basic-checked-background-color);
  border-color: var(--toggle-basic-checked-border-color);
}